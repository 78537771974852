import {graphql, useStaticQuery} from "gatsby";
import React, {useState} from "react";
import Header from "src/components/common/header";
import CommonHelmet from "src/components/common/helmet";
import Footer from "src/components/common/footer";
import FullScreenImageWithText from "src/components/common/fullscreen_image_with_text";

export default function OccupancySensingPage() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          occupancySensingPage {
            hero {
              alt
              image
              title
            }
            text
          }
        }
      }
      allFile(filter: {relativeDirectory: {eq: "pages/occupancy_sensing"}}) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(transformOptions: {fit: COVER}, quality: 60)
            }
          }
        }
      }
    }
  `);

  const metadata = data.site.siteMetadata.occupancySensingPage;
  return (
    <main className="font-sans text-black flex flex-col items-center justify-center">
      <div className="max-w-7xl relative">
        <Header heroStyle="dark" />
        <CommonHelmet />
        {/* Hero */}
        <FullScreenImageWithText
          image_alt={metadata.hero.alt}
          files={data.allFile.edges}
          image_name={metadata.hero.image}
          title={metadata.hero.title}
          style="dark"
        />
        <div
          className="content-padding font-light"
          dangerouslySetInnerHTML={{__html: metadata.text}}
        ></div>
        <Footer />
      </div>
    </main>
  );
}
